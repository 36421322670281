<template>
  <v-container fluid>
    <v-snackbar :value="isError" color="error">
      {{ isError ? getError.errorMessage : '' }}
      <v-btn color="white" text @click="setError({ isError: false, error: null})">Cerrar</v-btn>
    </v-snackbar>
    <v-snackbar :value="infoSnackbar" color="success">
      {{ infoSnackbar ? infoSnackbarMessage : '' }}
      <v-btn color="white" text @click="infoSnackbar = false">Cerrar</v-btn>
    </v-snackbar>
    <v-dialog v-model="showNuevoProductoDialog" width="500px">
      <v-card>
        <v-card-title>Crear Nuevo Producto</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pb-0">
          <v-layout>
            <v-row>
              <v-col cols="12" sm="12">
                <v-text-field outlined dense label="Nombre del Producto" v-model="nuevoProductoModel.nombreProducto"></v-text-field>
                <v-text-field outlined dense prefix="S/" label="Costo Base del Producto" v-model="nuevoProductoModel.costoBase"></v-text-field>
              </v-col>
            </v-row>
          </v-layout>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn text @click="showNuevoProductoDialog = false">Cancelar</v-btn>
          <v-spacer></v-spacer>
          <v-btn text color="green darken-1" :loading="isLoading" :disabled="isLoading" @click="crearProducto">Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showEditProductoDialog" width="500px">
      <v-card>
        <v-card-title>Editar Producto</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pb-0">
          <v-layout>
            <v-row>
              <v-col cols="12" sm="12">
                <v-text-field outlined dense label="Nombre del Producto" v-model="productoModel.nombreProducto"></v-text-field>
                <v-text-field outlined dense prefix="S/" label="Costo Base del Producto" v-model="productoModel.costoBase"></v-text-field>
              </v-col>
            </v-row>
          </v-layout>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn text @click="showEditTiendaDialog = false">Cancelar</v-btn>
          <v-spacer></v-spacer>
          <v-btn text color="green darken-1" :loading="isLoading" :disabled="isLoading" @click="editarProducto">Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row no-gutters>
      <v-col>
        <v-data-table
          fixed-header
          :headers="headers"
          :items="listaProductos"
          :loading="isLoading"
          loading-text="Cargando... Espera un Momento"
          no-data-text="No hay datos para mostrar"
          :search="buscarTabla"
          item-key="idProducto"
          class="elevation-1"
        >
          <template v-slot:top>
            <v-toolbar flat color="white">
              <div>
                <v-toolbar-title>Producto</v-toolbar-title>
              </div>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-btn icon color="success" class="mx-2" @click="actualizarTabla">
                <v-icon>mdi-reload</v-icon>
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn color="success" dark @click="showNuevoProductoDialog = true">
                Agregar
                <v-icon right>mdi-plus-circle-outline</v-icon>
              </v-btn>
            </v-toolbar>
            <v-layout class="py-3 d-flex">
              <v-row>
                <v-col cols="12" sm="12" md="8">
                  <v-text-field
                    v-model="buscarTabla"
                    prepend-inner-icon="mdi-magnify"
                    label="Buscar en la tabla..."
                    class="mx-4 flex-shrink-0 flex-grow-1"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-layout>
          </template>
          <template v-slot:item.costoBase="{ item }">
            S/ {{item.costoBase.toFixed(2)}}
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn icon color="teal" @click="abrirEditProductoDialog(item)"><v-icon>mdi-square-edit-outline</v-icon></v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {

  data: () => ({
    buscarTabla: '',
    trabajadores: [],
    listaProductos: [],
    headers: [
      { text: "ID", value: "idProducto", align: "center" },
      { text: "Producto", value: "nombreProducto", align: "center" },
      { text: "Costo Base", value: "costoBase", align: "center" },
      { text: 'Acciones', value: "actions", align: "center"}   
    ],
    infoSnackbar: false,
    showNuevoProductoDialog: false,
    showEditProductoDialog: false,
    nuevoProductoEmpty: {
      nombreProducto: '',
      costoBase: 0
    },
    nuevoProductoModel: {
      nombreProducto: '',
      costoBase: 0
    },
    productoModel: {
      nombreProducto: '',
      costoBase: 0
    },
    idProductoSeleccionado: 0
  }),

  methods: {
    formatDateTable(date) {
      let parsedDate = new Date(parseInt(date));
      let options = {
        year: 'numeric', month: '2-digit', day: '2-digit'
      }
      return `${parsedDate.toLocaleDateString('es-PE', options)} ${parsedDate.toLocaleTimeString()}`;
    },

    cleanSource(source) {
      return JSON.parse(JSON.stringify(source));
    },

    async actualizarTabla() {
      await this.fetchListaProductosClientes();
      this.setearListaProductos();
    },

    async crearProducto() {
      let response = await this.postNuevoProducto(this.nuevoProductoModel);
      if (response) {
        this.showNuevoProductoDialog = false;
        this.nuevoProductoModel = this.nuevoProductoEmpty;
        await this.actualizarTabla();
      }
    },

    abrirEditProductoDialog(item) {
      this.idProductoSeleccionado = item.idProducto;
      this.productoModel.nombreProducto = item.nombreProducto;
      this.productoModel.costoBase = item.costoBase;
      this.showEditProductoDialog = true;
    },

    async editarProducto() {
      let response = await this.putEditarProducto({idProducto: this.idProductoSeleccionado, producto: this.productoModel});
      if (response) {
        this.showEditProductoDialog = false;
        this.productoModel = this.nuevoProductoEmpty;
        await this.actualizarTabla();
      }
    },

    setearListaProductos() {
      this.listaProductos = this.getListaProductosFiltrada;
    },

    ...mapActions('user', ['fetchListaProductosClientes']),

    ...mapActions('producto', ['fetchListaProducto', 'postNuevoProducto', 'putEditarProducto'])
  },

  computed: {
    ...mapGetters('user', ['isLoading', 'isError', 'getError', 'getListaProductosFiltrada'])
  },

  mounted: async function() {
    await this.actualizarTabla();
    this.setearListaProductos();
  }


}
</script>

<style>

</style>